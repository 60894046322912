
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import { ElMessage } from 'element-plus'

@Options({
  components: {
    PagePlugin,
    CustomDialog
  }
})
export default class outcome_change extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  audit_status_234 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派产研院专员'],
    [6, '待产研院审核（转让许可）'],
    [7, '待指派权属委专员'],
    [8, '待权属委审核（转让许可）'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示完成'],
    [11, '待指派校章专员'],
    [12, '待盖章'],
    [13, '已通过'],
    [14, '待指派资产公司专员'],
    [15, '待资产公司审核（作价投资）'],
    [16, '待指派权属委专员'],
    [17, '待权属委审核（作价投资）'],
    [18, '待指派资产公司专员'],
    [19, '待指派异常审议人'],
    [20, '待异常审议'],
    [21, '待指派异常复审人'],
    [22, '待异常审议复审']
  ])
  users_dialog = {
    open: false,
    title: '请选择',
    users: [] as any[],
    user_ids: [] as number[],
    type: 1, // 1: 成果完成人, 2: 权属内部分配
    is_edit: false // 是否为编辑窗
  }
  addPerson(type: number, is_edit: boolean) {
    this.users_dialog.type = type
    this.users_dialog.is_edit = is_edit
    this.users_dialog.open = true
    this.users_dialog.users = [] as any[]
    this.users_dialog.user_ids = [] as number[]
    // 添加
    if (!is_edit) {
      if (type === 1) {
        this.t_add_data.assignments.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
    }
    // 编辑
    if (is_edit) {
      if (type === 1) {
        this.t_edit_data.assignments.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
    }
  }

  users_dialog_ok() {
    // 添加
    if (!this.users_dialog.is_edit) {
      if (this.users_dialog.type === 1) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            materials: [],
            assignments: 0,
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.dept = item.dept
          const index = this.t_add_data.assignments.findIndex((items) => {
            return items.id === temp.id
          })
          if (index < 0) this.t_add_data.assignments.push(temp)
        })
        this.users_dialog.open = false
      }
    }
    // 编辑
    if (this.users_dialog.is_edit) {
      if (this.users_dialog.type === 1) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            materials: [],
            assignments: 0,
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.dept = item.dept
          const index = this.t_edit_data.assignments.findIndex((items) => {
            return items.id === temp.id
          })
          if (index < 0) this.t_edit_data.assignments.push(temp)
        })
        this.users_dialog.open = false
      }
    }
  }

  users_dialog_cancle() {
    this.users_dialog.open = false
  }

  t_all_data = []

  t_total = 0
  t_search_data = {
    start_time: '',
    end_time: '',
    user_name: '',
    name: ''
  }

  rule_t_search_data = {}

  t_add_dialog = {
    open: false,
    title: '添加转化申请'
  }

  t_add_data = {
    code: '',
    name: '',
    party_a: '',
    party_b: '',
    party_a_proportion: 0,
    party_b_proportion: 0,
    sum: 0,
    assignments: [
      { name: '', id_number: '', assignments: 0, materials: [], type: 2 }
    ] as any[],
    type: 0,
    materials: []
  }

  rule_t_add_data = {
    code: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    name: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_a: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_b: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_a_proportion: [
      { required: true, message: '此项不能为空', trigger: 'blur' }
    ],
    party_b_proportion: [
      { required: true, message: '此项不能为空', trigger: 'blur' }
    ],
    type: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  t_detail_dialog = {
    open: false,
    title: '详情'
  }

  t_detail_data = {
    code: '',
    name: '',
    party_a: '',
    party_b: '',
    party_a_proportion: 0,
    party_b_proportion: 0,
    sum: 0,
    assignments: [] as any[],
    type: 0,
    materials: []
  }

  t_del_dialog = {
    open: false,
    title: '删除'
  }

  t_del_data = {
    id: 0
  }

  t_edit_dialog = {
    open: false,
    title: '编辑'
  }

  t_edit_data = {
    id: 0,
    code: '',
    name: '',
    party_a: '',
    party_b: '',
    party_a_proportion: 0,
    party_b_proportion: 0,
    sum: 0,
    assignments: [] as any[],
    type: 0,
    materials: []
  }

  rule_t_edit_data = {
    code: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    name: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_a: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_b: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    party_a_proportion: [
      { required: true, message: '此项不能为空', trigger: 'blur' }
    ],
    party_b_proportion: [
      { required: true, message: '此项不能为空', trigger: 'blur' }
    ],
    type: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }

  t_edit1_dialog = {
    open: false,
    title: '提交'
  }

  t_edit1_data = {
    id: 0
  }

  rule_t_edit1_data = {}

  type_712 = new Map<any, string>([
    [1, '转让许可'],
    [2, '作价投资']
  ])

  t_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.t_search_data.user_name,
      name: this.t_search_data.name
    }
    Api.http_outcome_changet0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_all_data = res.data.data
        this.t_total = res.data.total
      }
    })
  }
  t_add_init(row) {
    this.t_add_dialog.open = true
    this.setDialogName(this.t_add_dialog.title)
  }
  t_add_cancle() {
    this.t_add_dialog.open = false
    this.removeDialogName()
  }
  t_add_ok() {
    this.$refs.ref_t_add_data.validate((valid) => {
      if (valid) {
        if ((Number(this.t_add_data.party_a_proportion) + Number(this.t_add_data.party_b_proportion)) !== 100) return ElMessage.error('转化后成果所有权比例必须等于100%')
        let ratio = 0
        this.t_add_data.assignments.forEach(item => {
          ratio += Number(item.assignments)
        })
        if (ratio !== 100) return ElMessage.error('乙方内部分配比例之和必须等于100%')
        const postData = {
          code: this.t_add_data.code,
          name: this.t_add_data.name,
          party_a: this.t_add_data.party_a,
          party_b: this.t_add_data.party_b,
          party_a_proportion: this.t_add_data.party_a_proportion,
          party_b_proportion: this.t_add_data.party_b_proportion,
          sum: this.t_add_data.sum,
          assignments: this.t_add_data.assignments,
          type: this.t_add_data.type,
          materials: this.t_add_data.materials
        }
        Api.http_outcome_changet1(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_add_dialog.open = false
            this.removeDialogName()
            this.t_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  t_del_init(row) {
    this.t_del_data.id = row.id
    this.t_del_dialog.open = true
  }
  t_del_cancle() {
    this.t_del_dialog.open = false
  }
  t_del_ok() {
    const postData = {
      id: this.t_del_data.id
    }
    Api.http_outcome_changet2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_del_dialog.open = false
        this.t_get_all_data(0, 10)
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  t_detail_init(row) {
    Api.http_outcome_changetget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_detail_data.code = res.data.code
        this.t_detail_data.name = res.data.name
        this.t_detail_data.party_a = res.data.party_a
        this.t_detail_data.party_b = res.data.party_b
        this.t_detail_data.party_a_proportion = res.data.party_a_proportion
        this.t_detail_data.party_b_proportion = res.data.party_b_proportion
        this.t_detail_data.sum = res.data.sum
        this.t_detail_data.assignments = res.data.assignments
        this.t_detail_data.type = res.data.type
        this.t_detail_data.materials = res.data.materials
        this.t_detail_dialog.open = true
        this.setDialogName(this.t_detail_dialog.title)
      }
    })
  }
  t_detail_cancle() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }
  t_detail_ok() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }
  t_edit_init(row) {
    Api.http_outcome_changetget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_data.id = row.id
        this.t_edit_data.code = res.data.code
        this.t_edit_data.name = res.data.name
        this.t_edit_data.party_a = res.data.party_a
        this.t_edit_data.party_b = res.data.party_b
        this.t_edit_data.party_a_proportion = res.data.party_a_proportion
        this.t_edit_data.party_b_proportion = res.data.party_b_proportion
        this.t_edit_data.sum = res.data.sum
        this.t_edit_data.assignments = res.data.assignments
        this.t_edit_data.type = res.data.type
        this.t_edit_data.materials = res.data.materials
        this.t_edit_dialog.open = true
        this.setDialogName(this.t_edit_dialog.title)
      }
    })
  }
  t_edit_cancle() {
    this.t_edit_dialog.open = false
    this.removeDialogName()
  }
  t_edit_ok() {
    this.$refs.ref_t_edit_data.validate((valid) => {
      if ((Number(this.t_edit_data.party_a_proportion) + Number(this.t_edit_data.party_b_proportion)) !== 100) return ElMessage.error('转化后成果所有权比例必须等于100%')
      let ratio = 0
      this.t_edit_data.assignments.forEach(item => {
        ratio += Number(item.assignments)
      })
      if (ratio !== 100) return ElMessage.error('乙方内部分配比例之和必须等于100%')
      if (valid) {
        const postData = {
          id: this.t_edit_data.id,
          code: this.t_edit_data.code,
          name: this.t_edit_data.name,
          party_a: this.t_edit_data.party_a,
          party_b: this.t_edit_data.party_b,
          party_a_proportion: this.t_edit_data.party_a_proportion,
          party_b_proportion: this.t_edit_data.party_b_proportion,
          sum: this.t_edit_data.sum,
          assignments: this.t_edit_data.assignments,
          type: this.t_edit_data.type,
          materials: this.t_edit_data.materials
        }
        Api.http_outcome_changet5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit_dialog.open = false
            this.removeDialogName()
            this.t_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  t_edit1_init(row) {
    Api.http_outcome_changetget6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit1_data.id = row.id
        this.t_edit1_dialog.open = true
      }
    })
  }
  t_edit1_cancle() {
    this.t_edit1_dialog.open = false
  }
  t_edit1_ok() {
    this.$refs.ref_t_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.t_edit1_data.id
        }
        Api.http_outcome_changet6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit1_dialog.open = false
            this.t_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  t_init() {
    this.t_get_all_data(0, 10)
  }
  created() {
    this.t_init()
  }
}
